.root {
    max-width: 100%;
    padding: 20px;
    box-sizing: border-box;
  }
  
  .title {
    font-size: 24px;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .reviewItem {
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .reviewHeader {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }
  
  .avatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 15px;
  }
  
  .authorInfo {
    display: flex;
    flex-direction: column;
  }
  
  .authorName {
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .reviewDate {
    font-size: 14px;
    color: #666;
  }
  
  .reviewContent {
    font-size: 16px;
    line-height: 1.6;
  }
  
  .reviewText {
    margin-bottom: 10px;
  }
  
  .rating {
    font-size: 20px;
    color: #ffd700;
  }
  
  .starFilled,
  .starEmpty {
    margin-right: 2px;
  }
  
  .starEmpty {
    opacity: 0.3;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .root {
      padding: 15px;
    }
  
    .title {
      font-size: 20px;
    }
  
    .reviewItem {
      padding: 15px;
    }
  
    .avatar {
      width: 40px;
      height: 40px;
    }
  
    .authorName {
      font-size: 16px;
    }
  
    .reviewDate {
      font-size: 12px;
    }
  
    .reviewContent {
      font-size: 14px;
    }
  
    .rating {
      font-size: 18px;
    }
  }
  
  @media (max-width: 480px) {
    .root {
      padding: 10px;
    }
  
    .title {
      font-size: 18px;
    }
  
    .reviewItem {
      padding: 10px;
    }
  
    .avatar {
      width: 30px;
      height: 30px;
      margin-right: 10px;
    }
  
    .authorName {
      font-size: 14px;
    }
  
    .reviewDate {
      font-size: 11px;
    }
  
    .reviewContent {
      font-size: 13px;
    }
  
    .rating {
      font-size: 16px;
    }
  }