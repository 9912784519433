.mainwrapper{
    overflow-x: hidden;    
}

.containerStyle{
    margin: 1.5rem;
}

.avatar {
    flex-shrink: 0;
    margin-right: 22px;
  }

  .similar_searches {
    width: 100%;
  }
  
  .sublocations-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 1rem;
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .sublocation-item {
    box-sizing: border-box;
  }
  

.seller_info .customer_review {
    text-align: center;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 90%; /* Set a max-width to avoid full width */
  margin: 0 auto; /* Center the content horizontally */
  box-sizing: border-box; /* Ensure padding is included in the width */
  }
  
  .seller_info .customer_review img {
    width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 10px;
  margin: auto;
  }
  
  .seller_info .customer_review button {
    background-color: #ff4d4d;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
  }
  
  .seller_info .customer_review button:hover {
    background-color: #ff3333;
  }
  

.seller_info, .how_it_works, .customer_review, .cake_shops_list, .discounts, .similar_searches {
    margin: 20px;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
  }
  
  .step, .question, .review {
    margin-bottom: 20px;
  }
  
  .faq {
    padding: 20px;
    border: 1px solid #ddd;
    margin: 25px;
  }
  
  .faq h2 {
    margin-bottom: 15px;
  }
  
  .faq_item {
    margin-bottom: 10px;
  }
  
  .faq_question {
    cursor: pointer;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    /* background-color: #fff; */
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .faq_answer {
    height: 0;
    opacity: 0;
    overflow: hidden;
    transition: height 0.4s ease-out, opacity 0.4s ease-out;
    white-space: pre-wrap;
  }
  
  .faq_answer.open {
    opacity: 1;
  }
  
  .faq_answer_content {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    /* background-color: #fff; */
  }
  
  .faq_icon {
    font-size: 20px;
    font-weight: bold;
  }

  .how_it_works {
    text-align: center;
    padding: 20px;
  }
  
  .step {
    margin-bottom: 20px;
  }
  
  .step img {
    width: 100%;
    max-width: 300px; /* Set a maximum width for larger screens */
    height: auto;
    margin: 0 auto;
    display: block;
  }
  
  .step_icon {
    font-size: 2em;
    margin-bottom: 10px;
  }
  
  .step h3 {
    margin: 10px 0;
  }
  
  .step p {
    margin: 5px 0;
  }
  